import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { queryClient } from "./api/query";
import { QueryClientProvider } from "react-query";
//router
import { BrowserRouter } from "react-router-dom";

//store
import { Provider } from "react-redux";
//reducer
import { store } from "./store";

window.store = store;

let mode = process.env.NODE_ENV;
console.log("Environment---->", mode);

if (mode !== "development") {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Provider store={store}>        
          <App/>
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
