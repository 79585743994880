//router
import IndexRouters from "./router/index"

//scss
import "./assets/scss/socialv.scss"
import "./assets/scss/customizer.scss"
import "./custom.scss"
import { Component } from "react";
import { visitproduct, visits } from "./utilities/apiconfig";
import { firebaseConfig } from "./utilities/constant";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkNull } from "./utilities/generic";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { post } from "./api";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            location_pathname: '/',
            currentClass: ''
        };
    }

    componentDidMount() {
        this.registerVisit();
        try {
            // Initialize Firebase
            const app = initializeApp(firebaseConfig);
             getAnalytics(app);
            //console.log("analytics", analytics)
            // Initialize Performance Monitoring and get a reference to the service
            getPerformance(app);
            //console.log("perf", perf)
        } catch(e) {
            console.log("firebase config error", e)
        }
    }

    async registerVisit() {

        const navigatorname = navigator.browserDetection = (function () {
            let ua = navigator.userAgent, tem,
                M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return 'IE ' + (tem[1] || '');
            } else if (M[1] === 'Chrome') {
                tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
                if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
            if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
            return M.join(' ');
        })();

        let visit_id = ''
        let postId = ''
        let pathname = window?.location?.pathname
        if (checkNull(pathname) !== '') {
            let path_array = pathname.split('/')
            if (path_array.length > 0) {
                let _id = path_array[path_array.length - 1]
                if (_id.startsWith("V")) {
                    visit_id = _id
                }
                let pId = path_array[path_array.length - 2]
                if (pId.startsWith("P")) {
                    postId = pId
                }
            }
        }

        let visit_url = visits
        let payload = {
            "appType": "WEB",
            "screenWidth": window.screen.width,
            "screenHeight": window.screen.height,
            "appWidth": window.screen.width,
            "appHeight": window.screen.height,
            "details": {
                "browserName": navigatorname,
                "deviceMemory": navigator.deviceMemory,
                "language": navigator.language,
                "languages": navigator.language,
                "userAgent": navigator.userAgent,
                "hardwareConcurrency": window.navigator.hardwareConcurrency,
                "maxTouchPoints": window.navigator.maxTouchPoints
            },
            "hashCode": 757143747,
            'redirectId': visit_id,
        }


        if (pathname.includes('/shop/product')) {
            visit_url = `${visitproduct}${postId}/${visit_id}`
        }
        post(visit_url, payload);
    }

    render() {
        return (
            <div className="App">
                
                <div className={`${this.state.currentClass}`}>
                    <IndexRouters />
                    <ToastContainer />
                </div>
               
            </div>
        );
    }
}
