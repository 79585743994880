import { configureStore } from '@reduxjs/toolkit';
import settingReducer from './setting/reducers';
import authReducer from '../features/profile/login/reducers';
import dataReducer from './data/reducers';
import themeReducer from './theme/reducers';
export const store = configureStore({
  reducer: {
    setting: settingReducer,
    auth:authReducer,
    data:dataReducer,
    theme: themeReducer,
  },
  
});
