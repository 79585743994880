/**
 *
 * This file used to add enviroment configurations
 *
 **/

// url to call community backend
export const communitybaseurl = process.env.REACT_APP_API_URL;
// apis
export const tenant = `${communitybaseurl}tenant/v1/currentTenant`;
export const profilelogin = "profile/v1/login";
export const refreshtoken = `${communitybaseurl}profile/v1/refreshToken`;
export const profiledata = "profile/v1/me";
export const userprofiledata = `${communitybaseurl}profile/v1/me`;
export const hottestproducts = `${communitybaseurl}product/v1/widget`;
export const voucher = `${communitybaseurl}voucher/v1/widget`;
export const feeds = `${communitybaseurl}post/v1/feed`;
export const announcements = `${communitybaseurl}post/v1/announcements`;
export const shopbuzz = `${communitybaseurl}product/v1/product`;
export const shopbuzz_nonInteract = `${communitybaseurl}product/v1/products/nonInteracted`;
export const shopbuzz_unReviewed = `${communitybaseurl}product/v1/products/unreviewed`;
export const productfilter = `${communitybaseurl}product/v1/tag`;
export const productreview = `${communitybaseurl}product/v1/review`;
export const productrating = `${communitybaseurl}product/v1/rating`;
export const fileupload = `${communitybaseurl}asset/v1/upload/POST`;
export const posturl = `${communitybaseurl}post/v1/post`;
export const presignedurl = `${communitybaseurl}asset/v1/signedURL`;
export const post_asset = `${communitybaseurl}asset/v1/signedURL/post/asset`;
export const profile = `${communitybaseurl}profile/v1/profile/`;
export const contestentry = `${communitybaseurl}contest/v1/entry/`;
export const entryfileupload = `${communitybaseurl}asset/v1/upload/CONTEST_ENTRY`;
export const contestentrypost = `${communitybaseurl}contest/v1/post/`;
export const addpost = `${communitybaseurl}post/v1/post`;
export const chat_room = `${communitybaseurl}chat/v1/room/`;
export const chatroom = `${communitybaseurl}chat/v2/room/`;
export const transaction = `${communitybaseurl}wallet/v1/myWallet/transaction?`;
export const points_url = `${communitybaseurl}wallet/v1/myWallet`;
export const referral = `${communitybaseurl}profile/v1/referral/`;
export const visits = `${communitybaseurl}visit/v1/visit`;
export const visitproduct = `${communitybaseurl}shop/product/`;
export const search = `${communitybaseurl}search/v1/search`;
export const search_v2 = `${communitybaseurl}search/v2/search`;
export const search_product = `${communitybaseurl}search/v2/search/products`;
export const quizUrl = `${communitybaseurl}quiz/v1/quiz`;
export const questionUrl = `${communitybaseurl}quiz/v1/question`;
export const pushout = `${communitybaseurl}pushOut/v1`;
export const currencyConversion = `https://api.exchangerate.host/`;
export const exchangerate = `https://api.exchangerate.host/`;
export const profilephoto = `${communitybaseurl}asset/v1/upload/PROFILE_PHOTO`;
export const surveyQusUrl = `${communitybaseurl}survey/v1/question`;
export const surveyUrl = `${communitybaseurl}survey/v1/survey`;
export const notification = `${communitybaseurl}message/v1/message`;
export const usertag = `${communitybaseurl}profile/v1/search`;
export const hashtag = `${communitybaseurl}tag/v1/search/`;
export const vouchers = `${communitybaseurl}voucher/v1/voucher/available`;
export const myvouchers = `${communitybaseurl}voucher/v1/voucher/purchased`;
export const verifyemail = `${communitybaseurl}profile/v1/verifyEmail`;
export const verifyPhone = `${communitybaseurl}profile/v1/verifyPhone`;
export const productbooking = `${communitybaseurl}product/v1/booking/package`;
export const loyaltypoints = `${communitybaseurl}loyalty/v1/loyaltyRewardPoints/`;
export const influencerjoin = `${communitybaseurl}influencer/v1/join`;
export const messageunread = `${communitybaseurl}message/v1/messageUnreadCount`;
export const markread = `${communitybaseurl}message/v1/markRead`;
export const eventconfig = `${communitybaseurl}loyalty/v1/eventConfig`;
export const loyaltyRewardPoints = `${communitybaseurl}loyalty/v1/loyaltyRewardPointsNonPaged`;
export const cashtransaction = `${communitybaseurl}affiliate-transaction/v1/transaction?`;
export const posttag = `${communitybaseurl}post/v1/tag`;
export const widget = `${communitybaseurl}widget/v1/widget`;
export const influencerprogress = `${communitybaseurl}influencer/v1/influencerProgress`;
export const browsercurrency = `https://ipapi.co/json/`;
export const poll = `${communitybaseurl}poll/v1/`;
export const notification_count = `${communitybaseurl}message/v1/messageUnreadCount`;
export const message_count = `${communitybaseurl}chat/v1/unreadMessages`;
export const monetize = `${communitybaseurl}monetize/v1/monetize`;
export const chatunread = `${communitybaseurl}chat/v1/unreadMessagesRooms`;
export const pollList = `${communitybaseurl}poll/v1/questions`;
export const eventregister = `${communitybaseurl}eventEntry/v1/event/`;
export const get_eventregister = `${communitybaseurl}list/event/`;
export const tutorial = `${communitybaseurl}tutorial/v1/tutorial`;
export const cardsAssignedToMe = `${communitybaseurl}scratchCards/v1/cardsAssignedToMe`;
export const card = `${communitybaseurl}scratchCards/v1/card`;
export const availableCard = `${communitybaseurl}scratchCards/v1/purchase/available`;
export const cardsSentByMe = `${communitybaseurl}scratchCards/v1/cardsSentByMe`;
export const cardPurchase = `${communitybaseurl}scratchCards/v1/purchase`;
export const scratchConfig = `${communitybaseurl}scratchCards/v1/purchase/config`;
export const card_brand = `${communitybaseurl}event/admin/v1/eventType/SCRATCH_CARD_BRAND_SENT`;
export const influencer_config = `${communitybaseurl}influencer/v1/influencerConfig`;
export const eventEntry = `${communitybaseurl}eventEntry/v1/eventEntry/`;
export const eventHistory = `${communitybaseurl}eventEntry/v1/eventEntry/history`;
export const scratchCardLandingPage = `${communitybaseurl}scratchCards/v1/landingPage`;
export const referralGuide = `${communitybaseurl}profile/v1/referralGuide`;
export const myreferral = `${communitybaseurl}profile/v1/me/referral`;
export const referralShare = `${communitybaseurl}profile/v1/me/referral/share`;
export const shareTemplate = `${communitybaseurl}profile/v1/me/referral/shareTemplate`;
export const help = `${communitybaseurl}help/v1/help`;
export const popup = `${communitybaseurl}popUp/v1/popUp`;
export const unsubscribe = `${communitybaseurl}profile/v1/unsubscribe`;
export const changepassword = `${communitybaseurl}profile/v1/changePassword`;
export const setupPassword = `${communitybaseurl}profile/v1/setupPassword`;
export const nonInteractedPosts = `${communitybaseurl}post/v1/feed/nonInteractedPosts`;
export const earnPoints = `${communitybaseurl}profile/v1/me/earnedPoints`;
export const voucher_buy = `${communitybaseurl}voucher/v1/voucher/available/`;
export const onlineProfileCount = `${communitybaseurl}profile/v1/onlineProfileCount`;
export const shareEmail = `${communitybaseurl}profile/v1/me/referral/shareEmail`;
export const loginCustom = `${communitybaseurl}profile/v1/loginCustom`;
export const customerProduct = `${communitybaseurl}product/v1/product/customer/`;
export const whoozcookingProduct = `${communitybaseurl}product/v1/products/top`;
export const whoozcookingCuisine = `${communitybaseurl}category/v1/categories`;
export const generativeReview = `${communitybaseurl}generative/v1/product/review`;
export const userproductReview = `${communitybaseurl}product/v1/review`;
export const feedcreaters = `${communitybaseurl}post/v1/feed/creators`;
export const contestPastWinners = `${communitybaseurl}contest/v1/entry/winners`;
export const reviewables = `${communitybaseurl}profile/v1/reviewables`;
export const influencerLeaderboard = `${communitybaseurl}influencer/v1/leaderboard`;
export const influencerProgramInfo = `${communitybaseurl}influencer/admin/v1/program`;
export const gifts = `${communitybaseurl}gift/admin/v1/gifts`;
export const onboard = `${communitybaseurl}profile/v1/onboard`;
export const checkExists = `${communitybaseurl}profile/v1/checkExists`;
export const group = `${communitybaseurl}chat/v2/room/`;
export const groupV1 = `${communitybaseurl}chat/v1/room/`;
export const RAFWidgetConfig = `${communitybaseurl}profile/referafriend/widgetConfig`;

export const profileActivityRequest = (id: string) =>
  `${communitybaseurl}/profile/v1/profile/${id}/activity`;
