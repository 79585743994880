import {v4 as uuid} from 'uuid'
import {DeviceUUID} from 'device-uuid'
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { get, post } from '../api';
import constant, { transactionTypes } from './constant';
import { browsercurrency, loginCustom, pushout, refreshtoken, userprofiledata } from './apiconfig'
import { store } from '../store';
import { PROFILE_LOGOUT, STORE_PROFILE, STORE_DELETED_ACTION } from '../store/actiontypes';
import { ToasterMessage } from '../components/toast/toastMessage';
import { toPng } from 'html-to-image';
import moment from 'moment';

export const communitybaseurl = process.env.REACT_APP_API_URL;

export function checkNull(val) {
    try {
        if (
            val === undefined ||
            val === null ||
            val === " " ||
            val === "" ||
            val === null ||
            val === "null" ||
            val === "Null" ||
            val === "undefined" ||
            !val
        ) {
            val = "";
        }
    } catch (e) {
        val = "";
    }

    return val;
}


export function authClear() {
    try {
        localStorage.removeItem(constant.access_token)
        localStorage.removeItem(constant.expires_in)
        localStorage.removeItem(constant.refresh_token)
        localStorage.removeItem(constant.CITY)
        localStorage.removeItem(constant.TOWN);
        localStorage.removeItem(constant.loggedInId);

        setSessionIdCookie()
        store.dispatch({ type: PROFILE_LOGOUT })

    } catch (e) { }
}

export const checkAuth = async () => {
    try {

        let data = await refreshToken()
        if (data.status) {
            scheduleTokenRefresh(data.data.accessToken.expiresIn)
        }
        return data.status
    } catch (e) {
    }
}

// refresh 10 minutes before token expires
const REFRESH_INTERVAL_SECONDS = 600

export const scheduleTokenRefresh = (expiresIn) => {

        setTimeout(() => {
            refreshToken()
        }, (expiresIn - REFRESH_INTERVAL_SECONDS) * 1000)

}

export const isAuthenticated = () => {
    let time = localStorage.getItem(constant.expires_in)
    const d = new Date();
    d.setMinutes(time);
    let expiredTimeMillis = d.getTime();
    let currentTimeMillis = new Date().getTime();

    if (currentTimeMillis >= expiredTimeMillis) {
        return false
    } else {
        let token = localStorage.getItem(constant.access_token)
        return checkNull(token) !== ""
    }
}

export const setSessionIdCookie = (access_token) => {

    const sessionId = access_token ? access_token.slice(-20) : uuid() + "_guest"

    Cookies.set('sessionId', sessionId)
}

export const setDeviceIdCookie = () => {
    const deviceId = Cookies.get('deviceId')

    if(!deviceId) {
        Cookies.set('deviceId', new DeviceUUID().get())
    }
}

export const refreshToken = async () => {
    if (isAuthenticated()) {
        try {
            let data = await get(refreshtoken)
            if (data.status) {
                setSessionIdCookie(data.data.accessToken.accessToken)

                localStorage.setItem(constant.access_token, data.data.accessToken.accessToken)
                localStorage.setItem(constant.refresh_token, data.data.accessToken.refreshToken)
                localStorage.setItem(constant.expires_in, data.data.accessToken.expiresIn)
            } else {
                authClear()
                // reloadWindow();
            }
            return data
        } catch (e) {
            console.log("refersh token error", e)
        }
    }
}

export const getReaction = (reaction) => {
    switch (reaction) {
        case 'THUMB_UP':
            return `👍`;
        case 'SMILE':
            return `😄`
        case 'HEART':
            return `❤️`
        case 'LOL':
            return `😮`
        case 'SAD':
            return `😥`
        case 'THUMB_DOWN':
            return `👎`
        default:
            return null
    }
}
export const calculateDiscountPercentage = (originalPrice, discountPrice) => {
    if (originalPrice && discountPrice) {
        let discountPercentage = ((originalPrice - discountPrice) / originalPrice * 100).toFixed(0)
        return `${discountPercentage}%`
    }
}

export const getNumberSuffix = (value) => {
    let j = value % 10,
        k = value % 100;
    if (j === 1 && k !== 11) {
        return "st";
    }
    if (j === 2 && k !== 12) {
        return "nd";
    }
    if (j === 3 && k !== 13) {
        return "rd";
    }
    return "th";
}
export const getContestProgress = (startDate, endDate) => {
    let start = new Date(startDate),
        end = new Date(endDate),
        today = new Date();

    let q = Math.abs(today - start);
    let d = Math.abs(end - start);
    return Math.round((q / d) * 100);
}

export const getOrdinal = (number) => {
    if (number === 1) {
      return number + "st";
    } else if (number === 2) {
      return number + "nd";
    } else if (number === 3) {
      return number + "rd";
    } else {
      return number + "th";
    }
  };

export const saveToPng = (id) => {
    if(typeof document !== 'undefined') {
        const div = document.getElementById(id)

        if(div) {
                toPng(div, {cacheBust: true}).then((dataUrl) => {
                    const anchor = document.createElement('a')

                    anchor.download = `${id}${moment().format('YYYYMMDDTHmmss')}.png`
                    anchor.href = dataUrl
                    anchor.click()
                })
                .catch(() => console.error('Something went wrong'))


                return true
        }

        throw new Error(`Could not find element with id ${id}`)
    }

    throw new Error('Document is not defined')
}

export const socialMediaSahreEntry = (feed, origin) => {
    try {
        if (isAuthenticated()) {
            let share_url = `${pushout}`
            let payload = {
                "externalId": feed.id,
                "externalType": feed.postType,
                "origin": origin
            }
            post(share_url, payload)
        }
    } catch (e) { }

}

export const socialmediaUrls = (objectUrls) => {

    let socialmedia = constant.Facebook
    let facebookUrl = objectUrls.filter((link) => link.name === socialmedia)?.[0]?.shareUrl

    socialmedia = constant.Twitter
    let twitterurl = objectUrls.filter((link) => link.name === socialmedia)?.[0]?.shareUrl

    socialmedia = constant.LinkedIn
    let linkedInurl = objectUrls.filter((link) => link.name === socialmedia)?.[0]?.shareUrl

    socialmedia = constant.Reddit
    let redditurl = objectUrls.filter((link) => link.name === socialmedia)?.[0]?.shareUrl

    socialmedia = constant.WhatsApp
    let whatsAppurl = objectUrls.filter((link) => link.name === socialmedia)?.[0]?.shareUrl

    socialmedia = constant.Pinterest
    let pinteresturl = objectUrls.filter((link) => link.name === socialmedia)?.[0]?.shareUrl


    return {
        'facebookUrl': facebookUrl,
        'twitterurl': twitterurl,
        'linkedInurl': linkedInurl,
        'redditurl': redditurl,
        'whatsAppurl': whatsAppurl,
        'pinteresturl': pinteresturl,
    }

}

export const screen = (value) => {
    switch (value) {
        case '/quiz':
            return { menu: constant.quizzes, isCollapse: true }
        case '/contest':
        case '/contestlist':
            return { menu: constant.contest, isCollapse: false }
        case '/survey':
            return { menu: constant.survey, isCollapse: true }
        case '/shopbuzz':
            return { menu: constant.shop_buzz, isCollapse: false }
        case '/polls':
            return { menu: constant.polls, isCollapse: true }
        case '/scratch':
            return { menu: constant.scratch_card, isCollapse: false }
        case '/events':
            return { menu: constant.events, isCollapse: false }
        case '/chat':
            return { menu: constant.chat_room, isCollapse: false }
        case '/referral':
            return { menu: constant.refer_friend, isCollapse: false }
        case '/voucher':
            return { menu: constant.voucher, isCollapse: false }
        default:
            return { menu: constant.explore, isCollapse: false }
    }
    // } else if (window.location.pathname === '/') {
    //     defaultMenu = constant.
    //     history.push('/shop')
    // } 
}
export const resetLocalStorage = () => {
    localStorage.removeItem(constant.access_token)
    localStorage.removeItem(constant.expires_in)
    localStorage.removeItem(constant.refresh_token)
    localStorage.removeItem(constant.CITY)
    localStorage.removeItem(constant.TOWN);
}

export const getTransactionDetails = (type) => {
    const foundTransactionTypeEntry = Object.entries(transactionTypes).find(([key, _value]) => type.includes(key))

    if (foundTransactionTypeEntry) {
        return foundTransactionTypeEntry[1]
    }

    return type
}
export const getBanner = () => {
    let banner = require('../assets/images/login_banner.png');
    return banner;
}

export const navigateToProfilePage = (profileId, props, history = null) => {
    try {
        let nav_history = props?.history
        if (history) {
            nav_history = history
        }
        if (isAuthenticated()) {
            nav_history.push({ pathname: `/profile/${profileId}` })
        } else {
            toast(ToasterMessage.notAuth, { type: 'error' })
            if (checkNull(process.env.REACT_APP_WHOOZ_COOKING_URL)) {
                const newWindow = window.open(process.env.REACT_APP_WHOOZ_COOKING_URL, '_blank')
                if (newWindow) newWindow.opener = null
                return
            }
        }
    }
    catch (e) {
        console.error(e)
    }
}

export const getProfileURL = (profileId) => `${communitybaseurl}/profile/${profileId}`

export const navigateToLoginPage = (props, ...restParams) => {
    if (checkNull(process.env.REACT_APP_WHOOZ_COOKING_URL)) {
        props.history.push({ pathname: `/` })
        const newWindow = window.open(process.env.REACT_APP_WHOOZ_COOKING_URL, '_blank')
        if (newWindow) newWindow.opener = null
        return
    }
    props.history.push({ pathname: `/auth/signin`, ...restParams })
}

export const getCurrencyValues = () => {
    let inrRate = localStorage.getItem(constant.INR_RATE)
    let eurRate = localStorage.getItem(constant.EUR_RATE)
    let usdRate = localStorage.getItem(constant.USD_RATE)
    let gbpRate = localStorage.getItem(constant.GBP_RATE)
    if (checkNull(inrRate) === '') { inrRate = 1 }
    if (checkNull(eurRate) === '') { eurRate = 1 }
    if (checkNull(usdRate) === '') { usdRate = 1 }
    if (checkNull(gbpRate) === '') { gbpRate = 1 }
    let currencyList = {
        'inrRate': inrRate,
        'eurRate': eurRate,
        'usdRate': usdRate,
        'gbpRate': gbpRate,
    }

    return currencyList;
}
export const getBrowserData = async () => {
    let currency_url = `${browsercurrency}`
    let browser_data = await get(currency_url)
    let browserData = {}
    browserData.currency = 'USD';
    if (browser_data.status) {
        //browserData = browser_data.data
    }
    return browserData;
}

export const getEarnPoints = (type) => {
    let eventConfig = store.getState().data.eventConfig

    let earn_points = checkNull(eventConfig?.[type]?.pointsAwardedPerEvent) !== '' ? eventConfig?.[type]?.pointsAwardedPerEvent : 10
    return earn_points
}

export const customLogin = async (jwtToken) => {
    let payload = {
        "authenticationType": constant.LOGINCUSTOM,
        "token": jwtToken,
    }
    let loginRes = await post(loginCustom, payload)
    if (loginRes.status) {
        authClear()
        let accessToken = loginRes?.data?.accessToken?.accessToken
        let refreshToken = loginRes?.data?.accessToken?.refreshToken

        //no token then navigate to home page
        if (checkNull(accessToken) === '') {
            return
        } else {

            //valid token then store all the token
            localStorage.setItem(constant.access_token, accessToken)
            localStorage.setItem(constant.refresh_token, refreshToken)
            localStorage.setItem(constant.expires_in, loginRes.data.accessToken.expiresIn)

            //get profile details based on new token
            let profile_url = `${userprofiledata}`
            let profile_data = await get(profile_url)
            let profileData = profile_data?.data
            if (profileData) {
                localStorage.setItem(constant.loggedInId, profileData.id)
                store.dispatch({ type: STORE_PROFILE, payload: profileData })
            }
            return true;
        }
    }
}

export const deletedItemAction = (props, _shouldNavigate = true) => {
    if (_shouldNavigate) {
        props?.history.push('/')
    }
    store.dispatch({ type: STORE_DELETED_ACTION, payload: true })
}

export const getSelectedTabPage = (props) => {
    let dishes = 'Dishes'
    let dish_review = 'Dish Reviews'
    let chef_review = 'Chef Reviews'
    const pathname = props?.location?.pathname
    const getSelectedTabPage = (pathname.includes('/dishreviews') || pathname.includes('/shop/readreviews') || pathname.includes('/shop/writereviews')) ? dish_review : pathname.includes('/chefreviews') ? chef_review : dishes
    return getSelectedTabPage;
}