
import { STORE_THEME_COLORS } from "../actiontypes";

const initialState = {
  primary: "#FF0000",
  interactive: "#222222",
  secondary: "#ffffff",
  neutral: "#fff",
  high: "#434343",
  medium: "#666666",
  low: "#E3E3E3",

  menu: "#fff",
  screen: "#fff",
  feed: "#fff",
  post: "#fff",
  shadows: {
    post: "0 2px 5px rgba(0, 0, 0, 0.1)"
  }
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_THEME_COLORS:
      const data = action.payload;

      console.log({ data });

      const theme_colors = {
        // used for buttons, links, and other interactive elements ???
        primary: data.primaryColor,
        // used for hovering over??
        interactive: data.interactiveColor,
        // used for text ?
        secondary: data.secondaryColor,
        // used for backgrounds??
        neutral: initialState.neutral,
        // used for ??
        high: initialState.high,
        // used for ??
        medium: initialState.medium,
        // used for ??
        low: initialState.low,

        menu: data.menuBackground,
        screen: data.screenBackground,
        feed: data.feedBackground,
        post: data.postBackground,

        shadows: {
          post: initialState.shadows.post
        }
      };
      console.log("theme_colors", Object.assign({}, state, theme_colors));
      return Object.assign({}, state, theme_colors);
    default:
      return state;
  }
};
export default themeReducer;
