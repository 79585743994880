export const ToasterMessage = {
    postReport: "Report successfully sent!",
    postCreated: "Post created!",
    postDeleted: "Post deleted!",
    postShare: 'Post shared!',
    productShare: 'Product shared!',
    quizzShare: 'Shared quiz!',
    quizzSave: 'Saved quiz!',
    contestShare: 'Contest shared!',
    surveyShare: 'Survey shared!',
    referFriend: 'Referral code was copied to clipboard, you can share it or send it to your friends',
    profileUpdate: 'Profile updated!',
    productBooked: 'Your booking has been initiated. We will get back to you soon.',
    maxfile: 'You can upload a maximum of 10 files',
    notAuth: 'You are not logged in. Please register or login and try again.',
    entrymaxfile: 'You can upload a maximum of 4 files',
    fileRequired: 'Please choose a file.',
    markasRead: 'All messages marked as read!',
    reelsCreated: 'Reels created!',
    blogsCreated: 'Blogs created!',
    vlogsCreated: 'Vlogs created!',
    bookmark: 'Updated your bookmark!',
    evetsShare: 'Events shared!',
    pollCreated: 'Poll created!',
    pollAnswered: 'Poll answered',
    pollNotSelected: 'Please select your opinion',
    pollFileRequired: 'Minimum of 2 files required',
    dateRequired: 'Date fields are required',
    questionRequired: 'Question field is required',
    minimumAnswer: 'Minimum 2 answers required',
    eventRegister: 'Event registered!',
    pollCreateError: 'Unable to create poll!',
    all_required: 'All fields are required!',
    valid_email: 'Enter a valid email',
    valid_name: 'Enter a valid name',
    valid_mobile: 'Enter a valid mobile number',
    card_success:"Scratch card successfully created",
    no_card_available: "No card available",
    send_card: 'Card sent successfully',
    purchase_card: 'Card purchased successfully',
    remove_card: 'Card removed successfully',
    went_wrong: 'Something went wrong. Please try again later',
    linkCopied: 'Copied successfully',
    email_success: 'Email sent successfully',
    change_password_success: 'Password changed successfully',
    copy_promote_link: 'Please copy the link and Promote it on social media.',
    email_change_success: 'Email changed successfully',
    phone_change_success: 'Phone number changed successfully',
    not_available: "Item not available",
    group_joined: "Joined successfully",
    email_unsubscribe: 'Unsubscribed successfully',
    email_unsubscribe_notAuth: 'You are not logged in. Please login and try again.',
    unSubscribe_not_match: 'Your email does not match the unsubscribe email',
    buy_before_review: 'Please ensure you have actually purchased this product before leaving a review!'
}