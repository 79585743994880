import axios, { AxiosError } from "axios";
import constant from "../utilities/constant";
import { refreshtoken } from "../utilities/apiconfig";
import { checkNull } from "../utilities/generic";
import Cookies from "js-cookie";

const getSessionDeviceId = () => {
  return {
    deviceId: Cookies.get("deviceId"),
    sessionId: Cookies.get("sessionId"),
  };
};

type GetResponse<T> = {
  status: boolean;
  data?: T;
};

export const get = async <T>(
  api_url: string,
  headers = {},
  accessToken?: string
): Promise<GetResponse<T>> => {
  let access_token = accessToken || localStorage.getItem(constant.access_token);
  const { deviceId, sessionId } = getSessionDeviceId();

  const res: GetResponse<T> = {
    status: false,
  };

  try {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
        "X-Cohora-SessionId": sessionId,
        "X-Cohora-DeviceId": deviceId,
        ...headers,
      },
    };

    const response = await axios.get<T>(api_url, config);
    const json = await response.data;

    if (response.status === constant.CODE_SUCCESS) {
      res["status"] = true;
    }
    res["data"] = json;
    return res;
  } catch (e) {
    console.log(e);

    return handleError(e, res);
  }
};

export const post = async (
  api_url: string,
  data?: object,
  headers: object = {},
  accessToken?: string
) => {
  const { deviceId, sessionId } = getSessionDeviceId();
  let access_token = accessToken || localStorage.getItem(constant.access_token);

  let res = {
    status: false,
  };
  try {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
        "X-Cohora-SessionId": sessionId,
        "X-Cohora-DeviceId": deviceId,
        ...headers,
      },
    };
    const response = await axios.post(api_url, data, config);
    const json = await response.data;

    if (response.status === constant.CODE_SUCCESS) {
      res["status"] = true;
    }
    res["data"] = json;
    return res;
  } catch (e) {
    return handleError(e, res);
  }
};

export const update = async (api_url, data) => {
  let access_token = localStorage.getItem(constant.access_token);
  let res = {
    status: false,
  };
  try {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    };
    const response = await axios.put(api_url, data, config);
    const json = await response.data;

    if (response.status === constant.CODE_SUCCESS) {
      res["status"] = true;
      res["data"] = json;
    }
    return res;
  } catch (e) {
    return handleError(e, res);
  }
};

export const remove = async (api_url) => {
  let access_token = localStorage.getItem(constant.access_token);
  let res = {
    status: false,
  };
  try {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    };
    const response = await axios.delete(api_url, config);
    const json = await response.data;
    if (response.status === constant.CODE_SUCCESS) {
      res["status"] = true;
      res["data"] = json;
    }
    return res;
  } catch (e) {
    return handleError(e, res);
  }
};

export const getPresigned = async (api_url, file) => {
  let access_token = localStorage.getItem(constant.access_token);
  let res = {
    status: false,
  };
  try {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    };
    const response = await axios.get(api_url, config);
    const json = await response.data;

    if (response.status === constant.CODE_SUCCESS) {
      res["status"] = true;
      res["data"] = json;
      res["file"] = file;
    }
    return res;
  } catch (e) {
    return handleError(e, res);
  }
};

export const deleteWithData = async (api_url, data) => {
  let access_token = localStorage.getItem(constant.access_token);
  let res = {
    status: false,
  };
  try {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      data: data,
    };
    const response = await axios.delete(api_url, config);
    const json = await response.data;
    if (response.status === constant.CODE_SUCCESS) {
      res["status"] = true;
      res["data"] = json;
    }
    return res;
  } catch (e) {
    return handleError(e, res);
  }
};

export const defaultGET = async (api_url, header) => {
  let res = {
    status: false,
  };
  try {
    const response = await axios.get(api_url);
    const json = await response.data;

    if (response.status === constant.CODE_SUCCESS) {
      res["status"] = true;
      res["data"] = json;
    }
    return res;
  } catch (e) {
    return handleError(e, res);
  }
};

/**
 * Common method for handle 401 error.
 * @param {*} e Axios error
 * @param {object} res Default response if also second try was failed.
 * @returns Axios Response | Object Response
 */
export const handleError = async (e, res) => {
  const status = e.response ? e.response.status : null;
  if (status === 401) {
    return await refreshTokenAndRetry(e);
  } else if (status === 404) {
    res = e.response;
  }
  console.error(`We have the ${e.config.method.toUpperCase()} call error`, e);
  return res;
};

export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) {
    if (error instanceof AxiosError) {
      return error.response?.data.message || 'Something went wrong'
    }

    return error.message
  }
}

/**
 * This method used for generate token and then re-call failed api.
 *
 * Note:- Use only if got 401 - Authorization error.
 * @param {*} e Axios error
 * @returns Axios response
 */
export const refreshTokenAndRetry = async (e) => {
  let refresh_token = localStorage.getItem(constant.refresh_token);
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${refresh_token}`,
  };

  if (checkNull(refreshtoken) === "") {
    return true;
  }
  return axios
    .post(refreshtoken, JSON.stringify({ refreshToken: refresh_token }), {
      headers: headers,
    })
    .then((data) => {
      if (data.status === 200) {
        localStorage.setItem(
          constant.access_token,
          data.data.accessToken.accessToken
        );
        localStorage.setItem(
          constant.refresh_token,
          data.data.accessToken.refreshToken
        );
        localStorage.setItem(
          constant.expires_in,
          data.data.accessToken.expiresIn
        );
      }
      console.log(data);
      const originalRequestConfig = e.config;
      const headers = JSON.parse(
        JSON.stringify(originalRequestConfig["headers"])
      );
      headers["Authorization"] = `Bearer ${data.data.accessToken.accessToken}`;
      originalRequestConfig.headers = headers;
      return axios.request(originalRequestConfig);
    })
    .catch((error) => {
      console.log(error + " Need to login!!");
    });
};

export const getHeader = () => {
  let access_token = localStorage.getItem(constant.access_token);
  const config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  return config;
};
