export const STORE_TENANT = 'TENANT';
export const STORE_PROFILE = 'PROFILE';
export const PROFILE_LOGOUT = 'PROFILE_LOGOUT';
export const STORE_CUSTOM_CONTAINER = 'STORE_CUSTOM_CONTAINER';
export const STORE_CUSTOM_WIDGET = 'STORE_CUSTOM_WIDGET';
export const STORE_LANGUAGE = 'STORE_LANGUAGE';
export const STORE_BODY_BG = 'STORE_BODY_BG';
export const STORE_CURRENCY_INR = 'STORE_CURRENCY_INR';
export const STORE_CURRENCY_EUR = 'STORE_CURRENCY_EUR';
export const STORE_CURRENCY_USD = 'STORE_CURRENCY_USD';
export const STORE_CURRENCY_GBP = 'STORE_CURRENCY_GBP';
export const STORE_VOUCHER_WIDGET = 'STORE_VOUCHER_WIDGET';
export const STORE_EVENT_CONFIG = 'STORE_EVENT_CONFIG';
export const STORE_SEARCH_TEXT = 'STORE_SEARCH_TEXT';
export const STORE_BROWSER_DATA = 'STORE_BROWSER_DATA';
export const STORE_SMS_COUNT = 'STORE_SMS_COUNT';
export const STORE_PUSH_COUNT = 'STORE_PUSH_COUNT';
export const STORE_EMAIL_COUNT = 'STORE_EMAIL_COUNT';
export const STORE_CHAT_COUNT = 'STORE_CHAT_COUNT';
export const STORE_CHATROOM_COUNT = 'STORE_CHATROOM_COUNT';
export const STORE_NOTOPENED_CARD_COUNT = 'STORE_NOTOPENED_CARD_COUNT';
export const STORE_SHOP_FILTER = 'STORE_SHOP_FILTER';
export const STORE_POPUPS = 'STORE_POPUPS';
export const STORE_FRIENDS = 'STORE_FRIENDS';
export const STORE_THEME_COLORS = 'STORE_THEME_COLORS';
export const STORE_DELETED_ACTION = 'STORE_DELETED_ACTION';