import { STORE_PROFILE, PROFILE_LOGOUT } from "../../../store/actiontypes";

const initialState = {
  profile: {
    displayName: "Guest"
  }
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
      case STORE_PROFILE:
        return Object.assign({}, state, { profile: action.payload  });      
        case PROFILE_LOGOUT:
          return Object.assign({}, state, { profile: { displayName: "Guest"}  }); 
      default:
          return state;
      
  }
}
export default authReducer;