/** React library */
import React from "react";
/** React bootstrap */
import { Modal, Spinner } from "react-bootstrap";
import Confetti from 'react-confetti'
/** Project library */
import { checkNull } from '../../../utilities/generic';
import constant from '../../../utilities/constant';
/** Service */
import { popup } from "../../../utilities/apiconfig";
import { CgClose } from 'react-icons/cg';
import { post, } from "../../../api";
import { store } from "../../../store";
import { STORE_POPUPS } from "../../../store/actiontypes";
import './popupmodal.scss'
class PopUpModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            popUpList: [],
            popUpData: {},
            showModal: false,
            htmlData: '',
            htmlTemplate: '',
            closingModel: false,
        }
    }

    componentDidMount() {
        store.subscribe(() => {
            // setTimeout(() => {
            let popupList = store.getState().data.popupList;
            if (popupList.length > 0 && !this.state.showModal) {
                let _htmlTemplate = popupList[0]?.htmlTemplate
                this.setState({
                    showModal: true,
                    popUpData: popupList[0],
                    htmlTemplate: _htmlTemplate
                })

                if (checkNull(_htmlTemplate) !== '') {
                    this.getTemplateData(_htmlTemplate?.url)
                }
            }
            //}, 3000)
        });
    }

    async getTemplateData(template_url) {
        fetch(template_url).then(function (response) {
            // The API call was successful!
            return response.text();
        }).then((html) => {
            this.setState({
                htmlData: html
            })
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    }

    closePopup = async () => {
        this.setState({
            closingModel: true,
        })
        let popUpId = this.state.popUpData.id
        let poup_data = await post(`${popup}/${popUpId}/markAsOpen`)
        let data = poup_data?.data
        if (data) {
            this.setState({
                showModal: false,
                closingModel: false,
                popUpList: [],
                popUpData: {},
                htmlData: '',
                htmlTemplate: '',
            })
        }
        store.dispatch({ type: STORE_POPUPS, payload: [] })
        // setTimeout(() => {
        //     store.dispatch({ type: STORE_POPUPS, payload: [] })
        // }, 5000)
    }

    render() {
        return (
            <>
                {checkNull(this.state.htmlData) !== '' ?
                    <div id="template-modal">

                        <Modal show={this.state.showModal} centered style={{ backgroundColor: "transparent" }}>
                            <Modal.Header style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingBottom: '5px',
                                paddingRight: 0,
                                border: 0,
                            }}>
                                <div className='' style={{ fontSize: '16px', paddingRight: '10px' }} dangerouslySetInnerHTML={{ __html: this.state.popUpData?.popUpText }}></div>
                                <div className="d-flex align-items-center justify-content-center p-1">

                                    {this.state.closingModel ?
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            style={{ margin: '0 8px' }}
                                        />

                                        :

                                        <div className="p-1" style={{ cursor: "pointer", }} onClick={() => {
                                            this.closePopup()
                                        }}>
                                            <CgClose />
                                        </div>
                                    }
                                </div>
                            </Modal.Header>

                            <Modal.Body style={{ padding: 0 }}>
                                <div className='' style={{}} dangerouslySetInnerHTML={{ __html: this.state.htmlData }}></div>
                            </Modal.Body>
                        </Modal>
                    </div >
                    :
                    <Modal show={this.state.showModal} centered>

                        <Modal.Body className='m-0 p-0' style={{ width: '500px', height: "400px", backgroundColor: 'transparent' }}>

                            <>
                                <div className="d-flex justify-content-end" style={{ right: '10px', }}>
                                    <div className="d-flex align-items-center justify-content-center p-2">

                                        {this.state.closingModel ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{ margin: '0 8px' }}
                                            />

                                            :
                                            <div className="d-flex align-items-center" style={{ cursor: "pointer", }} onClick={() => {
                                                this.closePopup()
                                            }}>
                                                <CgClose />
                                            </div>

                                        }
                                    </div>
                                </div>
                                {checkNull(this.state.popUpData?.popUpStyle !== constant.PICTURE_BACKGROUND) &&
                                    <div className='h-100' style={{ width: '100%', height: "400px" }}>

                                        <Confetti
                                            style={{ width: '100%', height: "400px" }}
                                        />
                                        {/* <div className="" style={{ position: 'absolute', top: '10px', right: '10px', }}>
                               <div style={{ width: '30px', height: "30px", borderRadius: '20px', background: "white", cursor: "pointer", border: '1px solid grey' }} onClick={() => {
                                   this.closePopup()
                               }}><CgClose style={{ fontSize: '20px', cursor: 'pointer', marginTop: '4px', marginLeft: '4px', }} /></div>
                           </div> */}

                                        {/* Template 1 - PICTURE_IN_MIDDLE */}
                                        {checkNull(this.state.popUpData?.popUpStyle === constant.PICTURE_IN_MIDDLE) &&
                                            <div className="" style={{ width: '100%' }}>

                                                {checkNull(this.state.popUpData.content?.url) !== '' &&
                                                    <div className='d-flex align-items-center justify-content-center' style={{ backgroundColor: '#f5f5f5' }}>
                                                        {(checkNull(this.state.popUpData.content.type) === constant.IMAGE || checkNull(this.state.popUpData.content.mimeType) === constant.image_gif) ?
                                                            <div>
                                                                <img alt='' style={{ width: '200px' }} src={this.state.popUpData.content?.url} />
                                                            </div>
                                                            :
                                                            <div>
                                                                <video
                                                                    //style={{ width: '200px' }}
                                                                    controls
                                                                    controlsList="nodownload"
                                                                    style={{ maxWidth: '100%', width: '100%', height: 'auto', maxHeight: 'unset' }}
                                                                    webkit-playsinline="true"
                                                                    playsInline
                                                                    src={this.state.popUpData.content?.url} />
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <div className='d-flex align-items-center justify-content-center' style={{}}>

                                                    <div>
                                                        {checkNull(this.state.popUpData?.popUpText) !== '' &&
                                                            <div className='m-3' style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: this.state.popUpData.popUpText }}></div>
                                                        }
                                                        {checkNull(this.state.popUpData.buttonName) !== '' &&
                                                            <div className='mt-3 d-flex align-items-center justify-content-center'>
                                                                <button style={{
                                                                    background: '#4a96e7',
                                                                    color: 'white',
                                                                    padding: '10px',
                                                                    paddingInline: '20px',
                                                                    borderRadius: '5px',
                                                                    borderWidth: 0,
                                                                }}>{this.state.popUpData.buttonName}</button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/* Template 2 - PICTURE_LEFT */}
                                        {checkNull(this.state.popUpData?.popUpStyle === constant.PICTURE_LEFT) &&
                                            <div className="" style={{}}>

                                                <div className='d-flex align-items-center justify-content-center' style={{}}>
                                                    {checkNull(this.state.popUpData.content?.url) !== '' &&
                                                        <div className='d-flex align-items-center justify-content-center' style={{}}>
                                                            {(checkNull(this.state.popUpData.content.type) === constant.IMAGE || checkNull(this.state.popUpData.content.mimeType) === constant.image_gif) ?
                                                                <div style={{ width: '250px', height: "400px" }}>
                                                                    <img alt='' style={{ width: '100%', height: '100%' }} src={this.state.popUpData.content?.url} />
                                                                </div>
                                                                :
                                                                <div>
                                                                    <video
                                                                        //style={{ width: '200px' }}
                                                                        controls
                                                                        controlsList="nodownload"
                                                                        style={{ maxWidth: '100%', width: '100%', height: 'auto', maxHeight: 'unset' }}
                                                                        webkit-playsinline="true"
                                                                        playsInline
                                                                        src={this.state.popUpData.content?.url} />
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    <div className='ms-2 d-flex align-items-center justify-content-center' style={{ width: '250px' }}>

                                                        <div>
                                                            {checkNull(this.state.popUpData?.popUpText) !== '' &&
                                                                <div className='m-3' style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: this.state.popUpData.popUpText }}></div>
                                                            }
                                                            {checkNull(this.state.popUpData.buttonName) !== '' &&
                                                                <div className='mt-3 me-3 d-flex align-items-center justify-content-center'>
                                                                    <button style={{
                                                                        background: '#4a96e7',
                                                                        color: 'white',
                                                                        padding: '10px',
                                                                        paddingInline: '20px',
                                                                        borderRadius: '5px',
                                                                        borderWidth: 0,
                                                                    }}>{this.state.popUpData.buttonName}</button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }

                                {/* Template 3 - PICTURE_BACKGROUND */}
                                {checkNull(this.state.popUpData?.popUpStyle === constant.PICTURE_BACKGROUND) &&
                                    <div className="" style={{ height: '100%' }}>
                                        {/* <Confetti
                               style={{ width: '100%', height: "400px" }}
                           /> */}
                                        {/* <div className="" style={{ position: 'absolute', top: '10px', right: '10px', }}>
                               <div style={{ width: '30px', height: "30px", borderRadius: '20px', background: "white", cursor: "pointer", border: '1px solid grey', pointerEvents: 'auto', }} onClick={() => {
                                   this.closePopup()

                               }}><CgClose style={{ fontSize: '20px', cursor: 'pointer', marginTop: '4px', marginLeft: '4px', }} /></div>
                           </div> */}


                                        <div className='d-flex align-items-center justify-content-center'
                                            style={{
                                                height: '100%',
                                                pointerEvents: 'none',
                                            }}>
                                            {(checkNull(this.state.popUpData?.content?.mimeType) === 'image/gif' || checkNull(this.state.popUpData?.content?.type) === constant.IMAGE) ?
                                                <img alt=''
                                                    style={{ maxWidth: '100%', width: '100%', height: '100%', maxHeight: 'unset' }}
                                                    src={this.state.popUpData.content?.url} />

                                                :
                                                <video
                                                    //style={{ width: '200px' }}
                                                    controls
                                                    controlsList="nodownload"
                                                    style={{ maxWidth: '100%', width: '100%', height: '100%', maxHeight: 'unset' }}
                                                    webkit-playsinline="true"
                                                    playsInline
                                                    autoPlay={true}
                                                    src={this.state.popUpData.content?.url} />

                                            }
                                            <div className='p-2 d-flex align-items-center justify-content-center'
                                                style={{
                                                    border: '5px solid white',
                                                    width: '275px',
                                                    height: '350px',
                                                    background: '#00000026',
                                                    color: 'white',
                                                    position: "absolute",
                                                }}>

                                                <div>
                                                    {checkNull(this.state.popUpData?.popUpText) !== '' &&
                                                        <div className='m-3' style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: this.state.popUpData.popUpText }}></div>
                                                    }
                                                    {checkNull(this.state.popUpData.buttonName) !== '' &&
                                                        <div className='mt-3 d-flex align-items-center justify-content-center'>
                                                            <button style={{
                                                                background: '#4a96e7',
                                                                color: 'white',
                                                                padding: '10px',
                                                                paddingInline: '20px',
                                                                borderRadius: '5px',
                                                                borderWidth: 0,
                                                            }}>{this.state.popUpData.buttonName}</button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {/* <>

                                   <div className='d-flex align-items-center justify-content-center'
                                       style={{
                                           height: '450px',
                                           backgroundImage: checkNull(this.state.popUpData?.content?.type) === constant.IMAGE ? `url("${this.state.popUpData?.content?.url}")` : '',
                                           backgroundPosition: 'center',
                                           backgroundRepeat: 'no-repeat',
                                           backgroundSize: 'cover',
                                       }}>

                                       <div className='p-2 d-flex align-items-center justify-content-center'
                                           style={{
                                               border: '5px solid white',
                                               width: '275px',
                                               height: '350px',
                                               background: '#00000026',
                                               color: 'white',
                                           }}>

                                           <div>
                                               {checkNull(this.state.popUpData?.popUpText) !== '' &&
                                                   <div className='m-3' style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: this.state.popUpData.popUpText }}></div>
                                               }
                                               {checkNull(this.state.popUpData.buttonName) !== '' &&
                                                   <div className='mt-3 d-flex align-items-center justify-content-center'>
                                                       <button style={{
                                                           background: '#4a96e7',
                                                           color: 'white',
                                                           padding: '10px',
                                                           paddingInline: '20px',
                                                           borderRadius: '5px',
                                                           borderWidth: 0,
                                                       }}>{this.state.popUpData.buttonName}</button>
                                                   </div>
                                               }
                                           </div>
                                       </div>
                                   </div>
                               </> */}


                                    </div>
                                }
                            </>

                        </Modal.Body>
                    </Modal>

                }

            </>
        )
    }
}

export default PopUpModal;