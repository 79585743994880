import React from "react";
import loader from '../../assets/images/page-img/page-load-loader.gif';

export default class Loading extends React.Component {
    render() {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ width: '100vw', height: '100vh' }}>
                <div
                    className="col-sm-12 text-center progress-loading">
                    <img src={loader} alt="loader" style={{ height: "100px" }} />
                </div>
            </div>
        );
    }
}