import constant from "../../utilities/constant";
import {
  STORE_TENANT,
  STORE_CUSTOM_CONTAINER,
  STORE_CUSTOM_WIDGET,
  STORE_LANGUAGE,
  STORE_BODY_BG,
  STORE_CURRENCY_INR,
  STORE_CURRENCY_EUR,
  STORE_CURRENCY_USD,
  STORE_CURRENCY_GBP,
  STORE_VOUCHER_WIDGET,
  STORE_EVENT_CONFIG,
  STORE_SEARCH_TEXT,
  STORE_BROWSER_DATA,
  STORE_SMS_COUNT,
  STORE_PUSH_COUNT,
  STORE_EMAIL_COUNT,
  STORE_CHAT_COUNT,
  STORE_CHATROOM_COUNT,
  STORE_NOTOPENED_CARD_COUNT,
  STORE_SHOP_FILTER,
  STORE_POPUPS,
  STORE_FRIENDS,
  STORE_DELETED_ACTION,
} from "../actiontypes";

const initialState = {
  tenant: {},
  container: [
    { id: "1", widgetType: constant.NEWSFEED },
    { id: "2", widgetType: constant.WIDGETS }
  ],
  widgets: [
    { id: "1", widgetType: constant.PRODUCTS },
    { id: "2", widgetType: constant.ANNOUNCEMENT },
    { id: "3", widgetType: constant.VOUCHERS },
    { id: "4", widgetType: constant.INTEREST }
  ],
  language: "en",
  bodyBackgroundColor: '#e6efff',
  inrlist: '',
  eurlist: '',
  usdlist: '',
  gbplist: '',
  voucher: [],
  eventConfig: {},
  searchText: "",
  browserData: '',
  smsCount: 0,
  pushCount: 0,
  emailCount: 0,
  chatCount: 0,
  chatroomCount: 0,
  notOpenedCardCount: 0,
  shopfilter: [],
  showMenuIntro: false,
  showHeaderIntro: false,
  popupList: [],
  firendsList: [],
  showDeleteActionModal: false,
}

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_TENANT:
      return Object.assign({}, state, { tenant: action.payload });
    case STORE_CUSTOM_CONTAINER:
      return Object.assign({}, state, { container: action.payload });
    case STORE_CUSTOM_WIDGET:
      return Object.assign({}, state, { widgets: action.payload });
    case STORE_LANGUAGE:
      return Object.assign({}, state, { language: action.payload });
    case STORE_BODY_BG:
      return Object.assign({}, state, { bodyBackgroundColor: action.payload });
    case STORE_CURRENCY_INR:
      return Object.assign({}, state, { inrlist: action.payload });
    case STORE_CURRENCY_EUR:
      return Object.assign({}, state, { eurlist: action.payload });
    case STORE_CURRENCY_USD:
      return Object.assign({}, state, { usdlist: action.payload });
    case STORE_CURRENCY_GBP:
      return Object.assign({}, state, { gbplist: action.payload });
    case STORE_VOUCHER_WIDGET:
      return Object.assign({}, state, { voucher: action.payload });
    case STORE_EVENT_CONFIG:
      return Object.assign({}, state, { eventConfig: action.payload });
    case STORE_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.payload });
    case STORE_BROWSER_DATA:
      return Object.assign({}, state, { browserData: action.payload });
    case STORE_SMS_COUNT:
      return Object.assign({}, state, { smsCount: action.payload });
    case STORE_PUSH_COUNT:
      return Object.assign({}, state, { pushCount: action.payload });
    case STORE_EMAIL_COUNT:
      return Object.assign({}, state, { emailCount: action.payload });
    case STORE_CHAT_COUNT:
      return Object.assign({}, state, { chatCount: action.payload });
    case STORE_CHATROOM_COUNT:
      return Object.assign({}, state, { chatroomCount: action.payload });
    case STORE_NOTOPENED_CARD_COUNT:
      return Object.assign({}, state, { notOpenedCardCount: action.payload });
    case STORE_SHOP_FILTER:
      return Object.assign({}, state, { shopfilter: action.payload });
    case constant.INTRO:
      if (action.payload === 'HEADER') {
        return Object.assign({}, state, { showHeaderIntro: true, showMenuIntro: false });
      } else if (action.payload === 'MENU') {
        return Object.assign({}, state, { showHeaderIntro: false, showMenuIntro: true });
      }
      return Object.assign({}, state, { showHeaderIntro: false, showMenuIntro: false });
    case STORE_POPUPS:
      return Object.assign({}, state, { popupList: action.payload });
    case STORE_FRIENDS:
      return Object.assign({}, state, { firendsList: action.payload });
    case STORE_DELETED_ACTION:
      return Object.assign({}, state, { showDeleteActionModal: action.payload });
    default:
      return state;

  }
}
export default dataReducer;